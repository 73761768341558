<template>
    <div class="header">
        <div v-if="!isSearch" class="header-wrapper">
            <div class="cur-coin" @click="showCoinList = !showCoinList">
                {{curCoin.key}}
                <i class="el-icon-caret-bottom el-icon--right"></i>
            </div>
            <div v-if="showCoinList" class="coin-list">
                <div v-for="(item,index) in coinList" 
                :key="index" 
                :class="['coin-item', item.key == curCoin.key ? 'active' : '']" 
                @click="changeCurCoin(item)">
                    {{item.key}}
                </div>
            </div>
            <div class="right-nav">
                <img class="search" src="@/assets/images/index/search.png" @click="isSearch = true" />
                <img class="center" src="@/assets/images/index/center.png" @click="toAccount" />
                <!-- <div class="cart-box" @click="showCart = true">
                    <img class="cart" src="@/assets/images/index/cart.png" />
                    <div class="count">{{cartTotalNum > 99 ? '99+' : cartTotalNum}}</div>
                </div> -->
            </div>
        </div>
        <div v-else class="search-box">
            <div class="input-box">
                <i class="el-icon-search"></i>
                <input class="search-input" v-model="keyword" placeholder="Enter search content..." />
                <div class="line"></div>
                <div class="search-btn" @click="search">Search</div>
            </div>
            <i class="el-icon-close" @click="isSearch = false"></i>
        </div>
        <transition name="slide">
            <div v-if="showCart" class="shopping-cart">
                <div class="cart-top">
                    <div class="title">Cart</div>
                    <i class="el-icon-close" @click="showCart = false"></i>
                </div>
                <div class="cart-list">
                    <div v-for="(item,index) in cartList" :key="index" class="cart-item">
                        <img class="img" :src="item.picture" @click="toDetail(item.good_id)" />
                        <div class="right">
                            <div class="info-item">
                                <div class="goods-desc">
                                    <div class="name" @click="toDetail(item.good_id)">{{item.good_name}}</div>
                                    <div class="tags">
                                        <el-tag v-for="(val,key) in JSON.parse(item.sku)" :key="key" type="danger" size="mini" class="tag">{{val}}</el-tag>
                                    </div>
                                </div>
                                <i class="el-icon-delete" @click="deleteCart(item.id)"></i>
                            </div>
                            <div class="info-item">
                                <el-input-number size="mini" v-model="item.num" :step="1" :min="1" :max="100" step-strictly @change="changeVal(item.id,item.num)"></el-input-number>
                                <div class="price">{{$currencySymbol}}{{parseFloat(item.per_price)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cart-bottom">
                    <div class="total">
                        <div class="label">Total</div>
                        <div class="value">{{$currencySymbol}}{{cartTotalPrice}}</div>
                    </div>
                    <div class="check-btn" @click="toPayment">Checkout</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isSearch: false,
                showCoinList: false,
                keyword: '',
                showCart: false
            }
        },
        created() {
            this.$store.dispatch('getCoinList')
            // this.$store.dispatch('getCartList')
        },
        computed: {
            isLogin() {
                return this.$store.state.token
            },
            
            coinList() {
                return this.$store.state.coinList
            },
            
            curCoin() {
                return this.$store.state.curCoin
            },
            
            cartList() {
                return this.$store.state.cartList
            },
            
            cartTotalNum() {
                return this.$store.state.cartTotalNum
            },
            
            cartTotalPrice() {
                return this.$store.state.cartTotalPrice
            }
        },
        methods: {
            changeCurCoin(item) {
                this.$store.commit('setCurCoin',item)
                this.showCoinList = false
                location.reload()
            },
            
            toDetail(id) {
                this.showCart = false
                this.$router.push({
                    path: '/detail',
                    query: {
                        id
                    }
                })
            },
            
            search() {
                this.$router.push({
                    path: '/product',
                    query: {
                        keyword: this.keyword
                    }
                })
            },
            
            deleteCart(id) {
                this.$api.editCart({
                    id,
                    num: 0
                },true).then(res => {
                    this.$store.commit('setCartList', {
                        type: 'delete',
                        data: {
                            id
                        }
                    })
                })
            },
            
            changeVal(id,num) {
                // console.log(id)
                this.$api.editCart({
                    id,
                    num
                },true).then(res => {
                    this.$store.commit('setCartList', {
                        type: 'edit',
                        data: {
                            id,
                            num
                        }
                    })
                })
            },
            
            toAccount() {
                if(this.isLogin) {
                    this.$router.push({
                        path: '/AccountSummary'
                    })
                } else {
                    this.$router.push({
                        path: '/login'
                    })
                }
            },
            
            toPayment() {
                this.$router.push({
                    path: '/payment',
                    query: {
                        type: 'cart'
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .header {
            width: 100%;
            height: $header-height;
            box-sizing: border-box;
            padding: 0 $box-padding;
            border-bottom: 1px solid #e9e9e9;
            background-color: #FFFFFF;
            
            .header-wrapper {
                position: relative;
                z-index: 2;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: $max-width;
                height: 100%;
                margin: 0 auto;
                
                .cur-coin {
                    padding-left: 1.5rem;
                    font-size: 1.4rem;
                    color: #333333;
                    white-space: nowrap;
                    cursor: pointer;
                    
                    .el-icon-caret-bottom {
                        margin-left: 2px;
                    }
                }
                
                .coin-list {
                    position: absolute;
                    top: $header-height;
                    left: 0;
                    z-index: 40;
                    width: 12rem;
                    max-height: 40rem;
                    box-sizing: border-box;
                    border-radius: 2px;
                    background-color: #FFFFFF;
                    overflow-y: auto;
                    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
                    
                    .coin-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 4rem;
                        box-sizing: border-box;
                        padding: 0 1.2rem;
                        font-size: 1.4rem;
                        color: #333333;
                        white-space: nowrap;
                        cursor: pointer;
                        
                        &:hover {
                            color: #d1466e;
                            background-color: #f8f8f8;
                        }
                        
                        &:not(:last-child) {
                            border-bottom: 1px solid #e9e9e9;
                        }
                    }
                    
                    .active {
                        color: #d1466e;
                        background-color: #f8f8f8;
                    }
                }
                
                .right-nav {
                    display: flex;
                    align-items: center;
                    
                    .search {
                        display: block;
                        min-width: 1.6rem;
                        max-width: 1.6rem;
                        height: 1.6rem;
                        cursor: pointer;
                    }
                    
                    .center {
                        min-width: 2.5rem;
                        max-width: 2.5rem;
                        height: 2.5rem;
                        margin: 0 0 0 3.5rem;
                        cursor: pointer;
                    }
                    
                    .cart-box {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        
                        .cart {
                            min-width: 2.7rem;
                            max-width: 2.7rem;
                            height: 2.7rem;
                            margin-right: .6rem;
                        }
                        
                        .count {
                            min-width: 3.2rem;
                            max-width: 3.2rem;
                            height: 3rem;
                            line-height: 3rem;
                            box-sizing: border-box;
                            border-radius: 3rem;
                            font-size: 1.3rem;
                            color: #333333;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #ededed;
                        }
                    }
                }
            }
            
            .search-box {
                position: relative;
                z-index: 3;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: $max-width;
                height: 100%;
                margin: 0 auto;
                
                .input-box {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 0 0 0 2.5rem;
                    background-color: #f6f6f8;
                    
                    .el-icon-search {
                        margin-right: 1.5rem;
                        font-size: 1.8rem;
                        color: #000000;
                        font-weight: bold;
                        white-space: nowrap;
                    }
                    
                    .search-input {
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        padding: 0;
                        border: none;
                        outline: none;
                        font-size: 1.4rem;
                        color: #333333;
                        background-color: #f6f6f8;
                    }
                    
                    .line {
                        width: 1px;
                        height: 3rem;
                        background-color: #d8d8d8;
                    }
                    
                    .search-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 2.5rem;
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: #000000;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                }
                
                .el-icon-close {
                    margin-left: 1.5rem;
                    font-size: 2.2rem;
                    color: #000000;
                    font-weight: bold;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
            
            .shopping-cart {
                position: fixed;
                top: 0;
                right: 0;
                z-index: 70;
                width: 44rem;
                height: 100vh;
                box-sizing: border-box;
                background-color: #FFFFFF;
                box-shadow: 0 0 5px rgba(0, 0, 0, .2);
                
                .cart-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 7rem;
                    box-sizing: border-box;
                    padding: 0 3rem;
                    border-bottom: 1px solid #e9e9e9;
                    
                    .title {
                        max-width: 100%;
                        font-size: 2.4rem;
                        color: #000000;
                        word-wrap: break-word;
                    }
                    
                    .el-icon-close {
                        margin-left: 3rem;
                        font-size: 3rem;
                        color: #000000;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                }
                
                .cart-list {
                    width: 100%;
                    height: calc(100vh - 23rem);
                    box-sizing: border-box;
                    padding: 3rem 1.5rem;
                    overflow-y: auto;
                    
                    .cart-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 3rem;
                        }
                        
                        .img {
                            min-width: 8.5rem;
                            max-width: 8.5rem;
                            height: 8.5rem;
                            margin-right: 1.5rem;
                            cursor: pointer;
                        }
                        
                        .right {
                            width: 100%;
                            
                            .info-item {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                
                                &:not(:last-child) {
                                    margin-bottom: 1rem;
                                }
                                
                                .goods-desc {
                                    max-width: 100%;
                                    
                                    .name {
                                        width: 100%;
                                        margin-bottom: .5rem;
                                        font-size: 1.4rem;
                                        color: #333333;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        cursor: pointer;
                                    }
                                    
                                    .tags {
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        
                                        .tag {
                                            &:not(:last-child) {
                                                margin-right: .5rem;
                                            }
                                        }
                                    }
                                }
                                
                                .el-icon-delete {
                                    margin-left: 2rem;
                                    font-size: 1.8rem;
                                    color: #333333;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                                
                                .price {
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    color: #000000;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
                
                .cart-bottom {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 16rem;
                    box-sizing: border-box;
                    padding: 0 3rem 2rem;
                    border-top: 1px solid #e9e9e9;
                    
                    .total {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 3rem;
                        
                        .label {
                            margin-right: 1.5rem;
                            font-size: 2rem;
                            color: #000000;
                            white-space: nowrap;
                        }
                        
                        .value {
                            max-width: 100%;
                            font-size: 2rem;
                            font-weight: bold;
                            color: #000000;
                            word-break: break-all;
                        }
                    }
                    
                    .check-btn {
                        width: 100%;
                        height: 5rem;
                        line-height: 5rem;
                        box-sizing: border-box;
                        border-radius: 2px;
                        font-size: 1.5rem;
                        color: #FFFFFF;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #333333;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .header {
            width: 100%;
            height: $header-height;
            box-sizing: border-box;
            padding: 0 $h5-box-padding;
            border-bottom: 1px solid #e9e9e9;
            background-color: #FFFFFF;
            
            .header-wrapper {
                position: relative;
                z-index: 2;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;
                
                .cur-coin {
                    padding-left: .6rem;
                    font-size: 1.4rem;
                    color: #333333;
                    white-space: nowrap;
                    cursor: pointer;
                    
                    .el-icon-caret-bottom {
                        margin-left: 2px;
                    }
                }
                
                .coin-list {
                    position: absolute;
                    top: $header-height;
                    left: 0;
                    z-index: 40;
                    width: 12rem;
                    max-height: 40rem;
                    box-sizing: border-box;
                    border-radius: 2px;
                    background-color: #FFFFFF;
                    overflow-y: auto;
                    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
                    
                    .coin-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 4rem;
                        box-sizing: border-box;
                        padding: 0 1.2rem;
                        font-size: 1.4rem;
                        color: #333333;
                        white-space: nowrap;
                        cursor: pointer;
                        
                        &:hover {
                            color: #d1466e;
                            background-color: #f8f8f8;
                        }
                        
                        &:not(:last-child) {
                            border-bottom: 1px solid #e9e9e9;
                        }
                    }
                    
                    .active {
                        color: #d1466e;
                        background-color: #f8f8f8;
                    }
                }
                
                .right-nav {
                    display: flex;
                    align-items: center;
                    
                    .search {
                        display: block;
                        min-width: 1.4rem;
                        max-width: 1.4rem;
                        height: 1.4rem;
                        cursor: pointer;
                    }
                    
                    .center {
                        min-width: 2.3rem;
                        max-width: 2.3rem;
                        height: 2.3rem;
                        margin: 0 0 0 3rem;
                        cursor: pointer;
                    }
                    
                    .cart-box {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        
                        .cart {
                            min-width: 2.5rem;
                            max-width: 2.5rem;
                            height: 2.5rem;
                            margin-right: .6rem;
                        }
                        
                        .count {
                            min-width: 2.9rem;
                            max-width: 2.9rem;
                            height: 2.8rem;
                            line-height: 2.8rem;
                            box-sizing: border-box;
                            border-radius: 2.8rem;
                            font-size: 1.2rem;
                            color: #333333;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #ededed;
                        }
                    }
                }
            }
            
            .search-box {
                position: relative;
                z-index: 3;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;
                
                .input-box {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 0 0 0 1.5rem;
                    background-color: #f6f6f8;
                    
                    .el-icon-search {
                        margin-right: 1.2rem;
                        font-size: 1.6rem;
                        color: #000000;
                        font-weight: bold;
                        white-space: nowrap;
                    }
                    
                    .search-input {
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        padding: 0;
                        border: none;
                        outline: none;
                        font-size: 1.4rem;
                        color: #333333;
                        background-color: #f6f6f8;
                    }
                    
                    .line {
                        width: 1px;
                        height: 2rem;
                        background-color: #d8d8d8;
                    }
                    
                    .search-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 1.5rem;
                        font-size: 1.4rem;
                        font-weight: bold;
                        color: #000000;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                }
                
                .el-icon-close {
                    margin-left: 1.5rem;
                    font-size: 2rem;
                    color: #000000;
                    font-weight: bold;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
            
            .shopping-cart {
                position: fixed;
                top: 0;
                right: 0;
                z-index: 70;
                width: 100%;
                height: 100vh;
                box-sizing: border-box;
                background-color: #FFFFFF;
                box-shadow: 0 0 5px rgba(0, 0, 0, .2);
                
                .cart-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 6rem;
                    box-sizing: border-box;
                    padding: 0 2rem;
                    border-bottom: 1px solid #e9e9e9;
                    
                    .title {
                        max-width: 100%;
                        font-size: 2.2rem;
                        color: #000000;
                        word-wrap: break-word;
                    }
                    
                    .el-icon-close {
                        margin-left: 2rem;
                        font-size: 2.8rem;
                        color: #000000;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                }
                
                .cart-list {
                    width: 100%;
                    height: calc(100vh - 21rem);
                    box-sizing: border-box;
                    padding: 2rem 1.5rem;
                    overflow-y: auto;
                    
                    .cart-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 2rem;
                        }
                        
                        .img {
                            min-width: 8rem;
                            max-width: 8rem;
                            height: 8rem;
                            margin-right: 1rem;
                            cursor: pointer;
                        }
                        
                        .right {
                            width: 100%;
                            
                            .info-item {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                
                                &:not(:last-child) {
                                    margin-bottom: 1.5rem;
                                }
                                
                                .goods-desc {
                                    max-width: 100%;
                                    
                                    .name {
                                        width: 100%;
                                        margin-bottom: .5rem;
                                        font-size: 1.4rem;
                                        color: #333333;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        cursor: pointer;
                                    }
                                    
                                    .tags {
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        
                                        .tag {
                                            &:not(:last-child) {
                                                margin-right: .4rem;
                                            }
                                        }
                                    }
                                }
                                
                                .el-icon-delete {
                                    margin-left: 1rem;
                                    font-size: 1.6rem;
                                    color: #333333;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                                
                                .price {
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    color: #000000;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
                
                .cart-bottom {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 15rem;
                    box-sizing: border-box;
                    padding: 0 2rem;
                    border-top: 1px solid #e9e9e9;
                    
                    .total {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 3rem;
                        
                        .label {
                            margin-right: 1.5rem;
                            font-size: 1.8rem;
                            color: #000000;
                            white-space: nowrap;
                        }
                        
                        .value {
                            max-width: 100%;
                            font-size: 1.9rem;
                            font-weight: bold;
                            color: #000000;
                            word-break: break-all;
                        }
                    }
                    
                    .check-btn {
                        width: 100%;
                        height: 4.6rem;
                        line-height: 4.6rem;
                        box-sizing: border-box;
                        border-radius: 2px;
                        font-size: 1.4rem;
                        color: #FFFFFF;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #333333;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>